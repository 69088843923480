<template>
  <div style="padding: 20px" class="mt-3">
    <!-- Dialog to confirm deletion of single pezzi -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogdeletepezziconfirmation"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"> Conferma avanzamento </v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="dialogdeletepezziconfirmation = false"
            >ANNULLA</v-btn
          >
          <v-btn color="success" dark @click="confirmAvanzamento">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-toolbar rounede dense class="white--text" color="indigo" flat>
        <v-toolbar-title>Logistica – Picking list</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <h3>Analisi Attuale: {{ analisi }}</h3>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="getAvanzamentoPezzi('s')"
              v-on="on"
              v-bind="attrs"
              class="ml-5 mr-5"
              small
              ><v-icon color="primary">mdi-table</v-icon></v-btn
            >
          </template>
          <span>Analisi Sintetica</span>
        </v-tooltip> -->
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="getAvanzamentoPezzi('d')"
              v-on="on"
              v-bind="attrs"
              small
              ><v-icon color="primary">mdi-table-search</v-icon></v-btn
            >
          </template>
          <span>Analisi Dettagliata</span>
        </v-tooltip> -->
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-5"
              @click="filtra_menu_visible = !filtra_menu_visible"
              color="blue-grey lighten-5"
              small
              v-on="on"
              v-bind="attrs"
            >
              <v-icon color="primary">mdi-filter</v-icon>
            </v-btn>
          </template> -->
        <!-- <span>Filtra</span> -->
        <!-- </v-tooltip> -->
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="ripristina"
              color="blue-grey lighten-5"
              small
              v-on="on"
              v-bind="attrs"
            >
              <v-icon color="primary">mdi-filter-off</v-icon>
            </v-btn>
          </template>
          <span>Ripristina</span>
        </v-tooltip> -->
        <v-spacer></v-spacer>
        <v-btn @click="clickChiudi" color="blue-grey lighten-5" small>
          <v-icon color="error">mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5" v-if="filtra_menu_visible">
        <!-- <v-row align="center" justify="center">
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="riferimento"
              item-value="riferimento"
              label="Rif. Ordine"
              style="display: inline-block; max-width: 350px"
              v-model="riferimento_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="riferimento_search = ''"
                  color="primary"
                  >{{
                    riferimento_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="cod_articolo"
              item-value="cod_articolo"
              label="Cod. Articolo"
              style="display: inline-block; max-width: 350px"
              v-model="cod_articolo_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="cod_articolo_search = ''"
                  color="primary"
                  >{{
                    cod_articolo_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="cod_commessa"
              item-value="cod_commessa"
              label="Cod Commessa"
              style="display: inline-block; max-width: 350px"
              v-model="cod_commessa_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="cod_commessa_search = ''"
                  color="primary"
                  >{{
                    cod_commessa_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="des_macchina"
              item-value="des_macchina"
              label="Cod. Macchina"
              style="display: inline-block; max-width: 350px"
              v-model="cod_macchina_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="cod_macchina_search = ''"
                  color="primary"
                  >{{
                    cod_macchina_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="nominativo"
              item-value="nominativo"
              label="Nome Operatore"
              style="display: inline-block; max-width: 350px"
              v-model="nome_operatore_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="nome_operatore_search = ''"
                  color="primary"
                  >{{
                    nome_operatore_search == ""
                      ? "mdi-filter"
                      : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  @change="filtra"
                  dense
                  hide-details
                  label="Dalla Data"
                  style="display: inline-block; max-width: 350px"
                  v-model="data_da"
                  readonly
                  v-on="on"
                >
                  <template v-slot:append>
                    <v-icon
                      class="ml-2"
                      @click="data_da = ''"
                      color="primary"
                      >{{
                        data_da == "" ? "mdi-filter" : "mdi-filter-off"
                      }}</v-icon
                    >
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="data_da"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" md="2">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  @change="filtra"
                  dense
                  hide-details
                  label="Entro la Data"
                  style="display: inline-block; max-width: 350px"
                  v-model="data_a"
                  readonly
                  v-on="on"
                >
                  <template v-slot:append>
                    <v-icon class="ml-2" @click="data_a = ''" color="primary">{{
                      data_a == "" ? "mdi-filter" : "mdi-filter-off"
                    }}</v-icon>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="data_a"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-text-field
              @change="filtra"
              label="Avanzamento %"
              style="max-width=300px"
              type="number"
              v-if="analisi == 'sintetica'"
              v-model="perc_avanzamento_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="perc_avanzamento_search = null"
                  color="primary"
                  >{{
                    perc_avanzamento_search == null
                      ? "mdi-filter"
                      : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row> -->
      </v-card-text>
      <v-data-table :headers="header_pezzi" :items="items_pezzi">
        <template v-slot:[`item.edit`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col v-on="on" v-bind="attrs" md="4">
                <v-btn
                  class="primary--text"
                  style="float: left"
                  @click="handleClick(item)"
                  small
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-col>
            </template>
            <span>Aggiornare</span>
          </v-tooltip>
        </template>
        <!-- <template v-slot:[`item.delete`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="seletedPezzi(item)" small>
                <v-icon v-on="on" v-bind="attrs" color="red">
                  mdi-window-close
                </v-icon>
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </template> -->
      </v-data-table>
    </v-card>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import moment from "moment/moment";
export default {
  name: "TabellaPezzi",
  components: {},
  props: {},
  data() {
    return {
      //  Pezzi object Variable
      switch1: false,
      riferimento: "",
      cod_commessa: "",
      cod_articolo: "",
      cod_fase: "",
      des_fase: "",
      data: "",
      quantita: null,
      qta_commessa: null,
      qta_residua: null,
      precent_residua: "",
      selectedItem: {},
      // Update Dialog Variable
      update_pezzi_dialog: false,
      dialogdeletepezziconfirmation: false,
      menu3: false,
      // Variabili d'appoggio
      analisi: "",
      cod_articolo_search: "",
      cod_commessa_search: "",
      cod_macchina_search: "",
      data_da: "",
      note: "",
      data_a: "",
      filtra_menu_visible: false,
      nome_operatore_search: "",
      riferimento_search: "",
      perc_avanzamento_search: null,
      // Modal
      menu1: null,
      menu2: null,
      caricamento_dati: false,
      // Array
      header_pezzi: [],
      header_pezzi_dettagliato: [
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Data",
          sortable: false,
          value: "_ora",
        },
        {
          text: "Nominativo",
          sortable: false,
          value: "nominativo",
        },
        {
          text: "Des. Fase",
          sortable: false,
          value: "des_fase",
        },
        {
          text: "Des. Fase Successiva",
          sortable: false,
          value: "des_fase_successiva",
        },
        {
          text: "Quantità",
          sortable: false,
          value: "quantita",
        },
      ],
      items_pezzi: [],
      items_pezzi_visualizzati: [],
    };
  },
  methods: {
    confirmAvanzamento() {
      const confirm_req = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessecheckcontrollo",
          token: localStorage.user_token,
          id_commessa_movimenti: this.selectedItem.id_movimento,
          check_controllo: 1,
        },
      };
      this.$store.dispatch("api", confirm_req).then(() => {
        let i = this.items_pezzi.findIndex((it) => it == this.selectedItem);
        this.items_pezzi.splice(i, 1);
        this.selectedItem = {};
        this.dialogdeletepezziconfirmation = false;
      });
    },
    // function to select the pezzo for deletion
    seletedPezzi(item) {
      this.selectedItem = item;
      this.dialogdeletepezziconfirmation = true;
    },
    // Function in order to Populate Modal form with selected pezzi item detail
    handleClick(item) {
      this.selectedItem = item;
      this.dialogdeletepezziconfirmation = true;
    },
    clickChiudi() {
      this.$router.push("/home/production");
    },
    filtra() {
      let items_pezzi_tmp = [];
      this.items_pezzi.forEach((elem) => {
        items_pezzi_tmp.push(elem);
      });
      // Filtro il riferimento ordine
      if (this.riferimento_search != "") {
        // Faccio un array temporaneo
        let res = [];
        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_pezzi_tmp.forEach((elem) => {
          if (elem.riferimento == this.riferimento_search) {
            res.push(elem);
          }
        });
        // Modello la risposta provvisoria su "res".
        items_pezzi_tmp = res;
      }
      // Filtro il cod_articolo
      if (this.cod_articolo_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.cod_articolo == this.cod_articolo_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      //Filtro il codice Commessa
      if (this.cod_commessa_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.cod_commessa == this.cod_commessa_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro il codice macchina
      if (this.cod_macchina_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.des_macchina == this.cod_macchina_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro il nome dell'operatore
      if (this.nome_operatore_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.nominativo == this.nome_operatore_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la percentuale di avanzamento
      if (this.perc_avanzamento_search != null) {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.precent_residua <= this.perc_avanzamento_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la data_da
      if (this.data_da != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.data_js.isSameOrAfter(this.data_da)) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la data_a
      if (this.data_a != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.data_js.isSameOrBefore(this.data_a)) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      this.items_pezzi_visualizzati = [];
      this.items_pezzi_visualizzati = items_pezzi_tmp;
    },
    getAvanzamentoPezzi(param) {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessaavanzamento",
          token: localStorage.getItem("user_token"),
          tipo: "p",
          analisi: param,
          check_controllo: 0,
        },
      };
      this.caricamento_dati = true;
      this.$store.dispatch("api", request).then((res) => {
        // Definisco il tipo di analisi corrente in base alla chiamata
        this.analisi = param == "s" ? "sintetica" : "dettagliata";
        // Svuoto l'array che contiene i dati dell'avanzamento
        this.items_pezzi = [];
        // Popolo l'array che contiene i dati totali
        res.data.forEach((elem) => {
          this.items_pezzi.push(elem);
        });
        // Svuoto lo header dei e lo ripopolo a seconda dell'analisi
        if (param == "s") {
          this.header_pezzi = [];
          this.header_pezzi_sintetico.forEach((elem) => {
            this.header_pezzi.push(elem);
          });
        } else if (param == "d") {
          this.header_pezzi = [];
          this.header_pezzi_dettagliato.forEach((elem) => {
            this.header_pezzi.push(elem);
          });
          let data2 = {
            value: "edit",
            sortable: false,
          };
          this.header_pezzi.unshift(data2);
          let data1 = {
            value: "delete",
            sortable: false,
          };
          this.header_pezzi.push(data1);
        }
        this.caricamento_dati = false;
      });
    },
    ripristina() {
      // Resetto i valori dei filtri
      this.riferimento_search = "";
      this.cod_articolo_search = "";
      this.cod_macchina_search = "";
      this.nome_operatore_search = "";
      this.data_da = "";
      this.data_a = "";
      // Resetto i valori della tabella
      this.items_pezzi_visualizzati = [];
      this.items_pezzi.forEach((elem) => {
        this.items_pezzi_visualizzati.push(elem);
      });
    },
  },
  mounted() {
    this.getAvanzamentoPezzi("d");
  },
  watch: {
    items_pezzi: {
      immediate: true,
      deep: true,
      handler() {
        this.items_pezzi_visualizzati = [];
        this.items_pezzi.forEach((elem) => {
          elem.data_js = moment(
            elem._data.substring(6, 10) +
              "-" +
              elem._data.substring(3, 5) +
              "-" +
              elem._data.substring(0, 2)
          );
          // Aggiungo gli items ai pezzi visualizzati
          this.items_pezzi_visualizzati.push(elem);
        });
        // Eseguo un filtro, caso mai l'utente ne abbia messo uno.
        this.filtra();
      },
    },
  },
};
</script>
<style scoped></style>
